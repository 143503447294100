const Ticker = new (function Ticker() {
	window.requestAnimationFrame = (function () {
		return (
			window.requestAnimationFrame ||
			window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			function (callback) {
				window.setTimeout(callback, 1000 / 60);
			}
		);
	})();

	let listeners = [],
		totalListeners = 0;

	this.add = function (handler) {
		listeners.push(handler);
		totalListeners++;
		return handler;
	};
	this.remove = function (handler) {
		for (let k = 0; k < totalListeners; k++) {
			if (listeners[k] === handler) {
				return listeners.splice(k, 1);
			}
		}
	};
	function update() {
		let counter = totalListeners;
		while (counter--) {
			let listener = listeners[counter];
			listener && listener();
		}
		window.requestAnimationFrame(update);
	}
	update();
})();

const SimpleAnimations = new (function SimpleAnimations() {
	this.easings = {
		linear: function (t) {
			return t;
		},
		easeInQuad: function (t) {
			return t * t;
		},
		easeOutQuad: function (t) {
			return t * (2 - t);
		},
		easeInOutQuad: function (t) {
			return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
		},
		easeInCubic: function (t) {
			return t * t * t;
		},
		easeOutCubic: function (t) {
			return --t * t * t + 1;
		},
		easeInOutCubic: function (t) {
			return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
		},
		easeInQuart: function (t) {
			return t * t * t * t;
		},
		easeOutQuart: function (t) {
			return 1 - --t * t * t * t;
		},
		easeInOutQuart: function (t) {
			return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
		},
		easeInQuint: function (t) {
			return t * t * t * t * t;
		},
		easeOutQuint: function (t) {
			return 1 + --t * t * t * t * t;
		},
		easeInOutQuint: function (t) {
			return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
		},
	};

	this.animate = function (
		startValue,
		endValue,
		time,
		handler,
		ease,
		completeHandler,
	) {
		ease = ease || this.easings.easeInQuad;
		let frames = time * 60,
			frame = 0;
		function render() {
			frame++;
			handler(startValue + (endValue - startValue) * ease(frame / frames));
			frame >= frames &&
				Ticker.remove(render) &&
				completeHandler &&
				completeHandler();
		}
		return Ticker.add(render);
	};
})();

class ScrollTo {
	constructor() {
		document
			.querySelectorAll('[data-scroll-to]')
			.forEach((el) => this.init(el));
	}

	init(el) {
		const selector = el.getAttribute('data-scroll-to');
		const targetEl = document.querySelector(selector);
		const focusableEl = targetEl.querySelectorAll(
			'[data-input] input, button, a',
		);

		const scrollTargetContainer = document.querySelector('body');

		el.addEventListener('click', (e) => {
			e.preventDefault();

			let scrollTarget = scrollTargetContainer;
			let startY = scrollTargetContainer.scrollTop;

			let windowStartY = window.scrollY;
			if (window.scrollTo) {
				window.scrollTo(0, windowStartY + 1);
			} else {
				window.scrollTop = windowStartY + 1;
			}

			if (window.scrollY >= windowStartY) {
				scrollTarget = window;
				startY = windowStartY;

				if (window.scrollTo) {
					window.scrollTo(0, windowStartY);
				} else {
					window.scrollTop = windowStartY;
				}
			}
			let endY = startY + targetEl.getBoundingClientRect().top;

			let maxEndY = scrollTarget.scrollHeight - scrollTarget.clientHeight;
			if (!isNaN(maxEndY)) {
				endY = endY > maxEndY ? maxEndY : endY;
			}

			let distance = Math.abs(endY - startY);
			let time = distance / 500;
			time = time < 0.75 ? 0.75 : time;
			time = time > 2.3 ? 0.75 : time;

			SimpleAnimations.animate(
				startY,
				endY,
				time,
				(value) => {
					if (scrollTarget.scrollTo) {
						scrollTarget.scrollTo(0, value << 0);
					} else {
						scrollTarget.scrollTop = value << 0;
					}
				},
				SimpleAnimations.easings.easeInOutCubic,
			);

			if (focusableEl.length) {
				setTimeout(
					() => {
						focusableEl[0].focus();
					},
					time * 1000 + 200,
				);
			}
		});
	}
}

export default new ScrollTo();
