import customSelect from 'custom-select';

class Dropdown {
	constructor(el) {
		this.init(el);
	}
	init(el) {
		const container = el;
		el.dropdown = this;

		const selectEl = container.querySelector('select');
		this.cstSel = customSelect(selectEl)[0];

		container.customSelect = this.cstSel;
		const cstOptions = this.cstSel.panel.querySelectorAll(
			'.custom-select-option',
		);

		this.setValue(this.cstSel.value);

		Array.from(this.cstSel.select.options).forEach((option, index) => {
			cstOptions[index]?.classList.toggle('is-disabled', option.disabled);
		});

		this.cstSel.select.addEventListener('change', () =>
			this.setValue(this.cstSel.value),
		);
	}
	setValue(value) {
		if (value) {
			this.cstSel.value = value;
			this.cstSel.opener.innerHTML = `<span>${value}</span>`;
		}
	}
}

document.querySelectorAll('[data-dropdown]').forEach((el) => new Dropdown(el));

export default Dropdown;
