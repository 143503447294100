class Signal {
	handlers;
	constructor() {
		this.handlers = [];
	}

	_throwError() {
		throw new TypeError('Signal handler must be a function!');
	}

	add(handler, context) {
		if (typeof handler !== 'function') {
			this._throwError();
		}
		this.handlers.push({ handler, context });
		return handler;
	}

	remove(handler) {
		if (typeof handler !== 'function') {
			this._throwError();
		}

		const totalHandlers = this.handlers.length;
		for (let k = 0; k < totalHandlers; k++) {
			if (handler === this.handlers[k].handler) {
				this.handlers.splice(k, 1);
				return handler;
			}
		}
	}

	call(...args) {
		const totalHandlers = this.handlers.length;
		for (let k = 0; k < totalHandlers; k++) {
			const handlerData = this.handlers[k];
			handlerData.handler.apply(handlerData.context || null, args);
		}
	}

	delayedCall(delay = 16, ...args) {
		setTimeout(() => {
			this.call(...args);
		}, delay);
	}
}

export default Signal;
