const ClassName = {
	ACTIVE: '_active',
};

const INIT_SIZE = 3;

class ShowMore {
	constructor(el) {
		this.init(el);
	}

	init(el) {
		const content = el;
		const toggle = content.nextElementSibling;
		const hiddenItemsQty = content.children.length - INIT_SIZE;
		toggle.querySelector('[data-show-more-qty]').textContent = hiddenItemsQty;

		toggle?.addEventListener('click', (evt) => {
			evt.preventDefault();

			const isExpanded = toggle.getAttribute('aria-expanded') === 'true';
			const toggleText = toggle.querySelector('span');

			if (!toggle.showText && !toggle.hideText) {
				toggle.showText = toggleText.textContent;
				toggle.hideText = toggle.getAttribute('data-show-more-toggle');
			}

			toggleText.textContent = isExpanded ? toggle.showText : toggle.hideText;
			content.classList.toggle(ClassName.ACTIVE, !isExpanded);
			toggle.setAttribute('aria-expanded', !isExpanded);
		});
	}
}

document.querySelectorAll('[data-show-more]').forEach((el) => new ShowMore(el));

export default ShowMore;
