class BackPage {
	constructor() {
		this.init();
	}
	init() {
		const buttonsBack = document.querySelectorAll('[data-back-btn]');

		buttonsBack.forEach((button) => {
			button.addEventListener('click', () => {
				if (!document.referrer) {
					window.location.href = '/';
					return;
				}

				window.history.back();
			});
		});
	}
}

export default new BackPage();
