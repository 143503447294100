import Swiper from 'swiper';
import { FreeMode, Thumbs, Mousewheel, Pagination } from 'swiper/modules';

class ProductFullscreen {
	constructor() {
		this.init();
	}

	init() {
		const container = document.querySelector('[data-product-fullscreen]');

		if (!container) {
			return;
		}

		this.gallery = container.querySelector('[data-product-fullscreen-gallery]');
		this.preview = container.querySelector('[data-product-fullscreen-preview]');

		const paginationElement = container.querySelector('.swiper-pagination');

		const sliderPreview = new Swiper(this.preview, {
			spaceBetween: 8,
			direction: 'vertical',
			slidesPerView: 'auto',
			freeMode: true,
			watchSlidesProgress: true,
		});

		new Swiper(this.gallery, {
			slidesPerView: 'auto',
			height: window.innerHeight,
			modules: [FreeMode, Thumbs, Mousewheel, Pagination],
			mousewheel: {
				invert: false,
			},
			thumbs: {
				swiper: sliderPreview,
			},
			freeMode: false,
			pagination: {
				el: paginationElement,
			},
			breakpoints: {
				640: {
					direction: 'vertical',
					freeMode: {
						enabled: true,
						sticky: false,
					},
				},
			},
		});

		this.changeImages();
	}
	changeImages(numberImg = 1) {
		this.gallery.querySelectorAll('.swiper-slide').forEach((slide, index) => {
			slide.innerHTML = `<img class="product-fullscreen__img full-size-img" src="/assets/images/product-fullscreen/product-fullscreen-gallery-${numberImg}-${index + 1}.jpg" alt="">`;
		});

		this.preview.querySelectorAll('.swiper-slide').forEach((slide, index) => {
			slide.innerHTML = `<img class="product-fullscreen__img-preview full-size-img" src="/assets/images/product-fullscreen/product-fullscreen-preview-${numberImg}-${index + 1}.jpg" alt="">`;
		});
	}
}

export default new ProductFullscreen();
