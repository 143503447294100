import SimpleBar from 'simplebar';
import GuideSizes from '../popups/guide-sizes';

class CustomScroll {
	constructor(container) {
		this.init(container);
	}

	init(container) {
		const scroll = new SimpleBar(container, {
			autoHide: false,
		});

		scroll.getScrollElement().addEventListener('scroll', () => {
			GuideSizes.init();
		});
	}
}

document
	.querySelectorAll('[data-custom-scroll]')
	.forEach((el) => new CustomScroll(el));

export default CustomScroll;
