import IMask from 'imask';

class Input {
	constructor(container) {
		this.init(container);
	}

	init(container) {
		this.container = container;
		this.input = this.container.querySelector('input, textarea');

		if (!this.input) {
			return;
		}

		this.container.customInput = this;
		this.type = this.container.getAttribute('data-input');
		this.clear = this.container.querySelector('[data-input-clear]');
		this.hints = this.container.querySelector('.input__hints');

		this.clear.addEventListener('click', () => {
			this.setValue('');
		});

		this.container.classList.toggle('_has-value', this.input.value.length);
		this.input.addEventListener('change', () => {
			let cleanValue = this.input.value;

			if (this.type === 'tel') {
				cleanValue = cleanValue.replace(/\D/g, '');
				this.input.value = cleanValue;
			}

			this.container.classList.toggle('_has-value', cleanValue.length);
		});
		this.input.addEventListener('input', () => {
			let cleanValue = this.input.value;

			if (this.type === 'tel') {
				cleanValue = cleanValue.replace(/\D/g, '');
				this.input.value = cleanValue;
			}

			this.container.classList.toggle('_has-value', cleanValue.length);
		});

		if (this.type === 'birthdate') {
			IMask(this.input, {
				mask: Date,
				min: new Date(1900, 1, 1),
				max: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
			});
		}

		if (this.hints) {
			this.input.addEventListener('input', () => {
				this.hints.hidden = !this.input.value;
			});
			this.input.addEventListener('focus', () => {
				this.hints.hidden = !this.input.value;
			});
			window.addEventListener('click', (evt) => {
				if (!this.container.contains(evt.target)) {
					this.hints.hidden = true;
				}
			});
			Array.from(this.hints.children).forEach((hint) => {
				hint.addEventListener('click', () => {
					this.input.value = hint.getAttribute('data-input-hint-value');
					this.hints.hidden = true;
				});
			});
		}
	}
	setValue(value) {
		this.input.value = value;
		this.input.dispatchEvent(new Event('input'));
	}
}

document.querySelectorAll('[data-input]').forEach((input) => new Input(input));

export default Input;
