const ClassName = {
	SHOWED: '_showed',
	ACTIVE: '_active',
};

class ShowInformation {
	constructor(el) {
		this.init(el);
	}

	init(el) {
		const button = el;
		const content = button.nextElementSibling;
		const textElement = button.querySelector('span');
		const showText = textElement.textContent;
		const hideText = button.dataset.hideText;
		const accordion = button.closest('[data-accordion]');
		const accordionContent = accordion.querySelector(
			'[data-accordion-content]',
		);
		const offset = 10;

		button.addEventListener('click', () => {
			textElement.textContent =
				textElement.textContent === showText ? hideText : showText;
			button.classList.toggle(ClassName.SHOWED);
			content.classList.toggle(ClassName.SHOWED);

			if (content.classList.contains(ClassName.SHOWED)) {
				accordion.style.setProperty(
					'--height',
					`${accordionContent.scrollHeight}px`,
				);
			} else {
				accordion.style.setProperty(
					'--height',
					`${accordionContent.scrollHeight - textElement.offsetHeight - offset}px`,
				);
			}
		});
	}
}

document
	.querySelectorAll('[data-show-information]')
	.forEach((el) => new ShowInformation(el));

export default ShowInformation;
