import intlTelInput from 'intl-tel-input';
import IMask from 'imask';
import env from '../utils/env';

class Validation {
	constructor() {
		this.init();
	}

	init() {
		this.phoneValidation();
	}
	phoneValidation() {
		const getInputMask = (input) => input.placeholder.replace(/[0-9]/g, '0');

		const initInputTelMask = (input) => {
			const inputMask = getInputMask(input);
			input.mask = IMask(input, { mask: inputMask });
		};

		const updateInputTelMask = (input) => {
			const inputMask = getInputMask(input);
			input.mask.updateOptions({ mask: inputMask });
		};

		const phones = document.querySelectorAll('[data-input="tel"] input');

		phones.forEach((phone) => {
			const iti = intlTelInput(phone, {
				utilsScript: `${env.assetsPath}/jsons/tel-input-utils.js`,
				initialCountry: 'ru',
				onlyCountries: [
					'ru',
					'am',
					'by',
					'kg',
					'uz',
					'kz',
					'tj',
					'ge',
					'ee',
					'lv',
					'lt',
					'it',
					'es',
					'fr',
					'th',
					'id',
					'cn',
					'mn',
					'il',
					'qa',
					'ae',
					'az',
					'bg',
					'be',
					'hu',
					'de',
					'gr',
					'dk',
					'cy',
					'nl',
					'no',
					'pl',
					'pt',
					'md',
					'ro',
					'rs',
					'sk',
					'si',
					'fi',
					'hr',
					'me',
					'cz',
					'ch',
					'se',
				],
				separateDialCode: true,
				customPlaceholder: (selectedCountryPlaceholder) =>
					selectedCountryPlaceholder,
			});

			phone.setAttribute(
				'data-country-code',
				`+${iti.getSelectedCountryData().dialCode}`,
			);

			phone.addEventListener('countrychange', () => {
				phone.value = '';
				phone.setAttribute(
					'data-country-code',
					`+${iti.getSelectedCountryData().dialCode}`,
				);
				if (phone.mask) {
					updateInputTelMask(phone);
				}
			});

			iti.promise.then(() => {
				initInputTelMask(phone);
			});
		});
	}
}

export default new Validation();
