class ReadMore {
	constructor(el) {
		this.init(el);
	}

	init(el) {
		this.container = el;

		if (!this.container) {
			return;
		}

		const button = this.container.querySelector('[data-read-more-button]');
		const buttonTextEl = button.querySelector('.read-more__button-text');
		const buttonText = {
			open: buttonTextEl.textContent,
			close: button.getAttribute('data-read-more-button'),
		};
		const readMoreContent = this.container.querySelector('.read-more__content');

		this.opened = false;

		button.addEventListener('click', (evt) => {
			evt.preventDefault();

			this.opened = !this.opened;
			this.container.classList.toggle('_active', this.opened);
			button.setAttribute('aria-expanded', this.opened);
			buttonTextEl.textContent = this.opened
				? buttonText.close
				: buttonText.open;

			if (this.opened) {
				readMoreContent.style.maxHeight = readMoreContent.scrollHeight + 'px';
				readMoreContent.addEventListener(
					'transitionend',
					() => {
						readMoreContent.style.maxHeight = 'max-content';
					},
					{ once: true },
				);
			} else {
				readMoreContent.style.maxHeight = readMoreContent.scrollHeight + 'px';
				setTimeout(() => {
					readMoreContent.removeAttribute('style');
				}, 50);
			}
		});
	}
}

document.querySelectorAll('[data-read-more]').forEach((el) => new ReadMore(el));

export default ReadMore;
