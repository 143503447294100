const ClassName = {
	SHOWED: '_showed',
};

class Toast {
	constructor() {
		this.init();
	}

	init() {
		document.querySelectorAll('[data-toast-opener]').forEach((el) => {
			const button = el;

			if (!button) {
				return;
			}

			button.addEventListener('click', () => {
				const id = button.dataset.toastOpener;
				this.showToast(id);
			});
		});
	}
	showToast(id) {
		const toast = document.getElementById(id);
		if (toast) {
			this.closeActiveToast();
			toast.classList.add(ClassName.SHOWED);
			setTimeout(() => {
				toast.classList.remove(ClassName.SHOWED);
			}, 3000);
		}
	}
	open(name) {
		this.showToast(name);
	}
	close() {
		this.closeActiveToast();
	}
	closeActiveToast() {
		const activeToast = document.querySelector(`.${ClassName.SHOWED}`);
		if (activeToast) {
			activeToast.classList.remove(ClassName.SHOWED);
		}
	}
}

export default new Toast();
