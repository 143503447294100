const ClassName = {
	LOADED: '_loaded',
};

class LazyAnimation {
	constructor() {
		this.init();
	}

	init() {
		document.querySelectorAll('[loading="lazy"]').forEach((item) => {
			item.complete && item.classList.add(ClassName.LOADED);
			if (!item.classList.contains(ClassName.LOADED) && !item.lazyanim) {
				item.lazyanim = true;
				item.addEventListener(
					'load',
					() => {
						item.classList.add(ClassName.LOADED);
					},
					{ once: true },
				);
			}
		});
	}
}

export default new LazyAnimation();
