class SignIn {
	constructor() {
		this.init();
	}

	init() {
		const container = document.querySelector('[data-sign-in]');

		if (!container) {
			return;
		}

		const inputs = container.querySelectorAll('input');

		inputs.forEach((input, index) => {
			input.addEventListener('input', (event) => {
				const value = event.target.value;

				if (value.length === 1 && value.match(/^[0-9]$/)) {
					const nextInput = inputs[index + 1];
					if (nextInput) {
						nextInput.focus();
					}
				} else {
					event.target.value = '';
				}
			});

			input.addEventListener('keydown', (event) => {
				if (event.key === 'Backspace' && input.value === '' && index > 0) {
					inputs[index - 1].focus();
				}
			});
		});
	}
}

export default new SignIn();
