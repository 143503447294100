import Swiper from 'swiper';

const MOBILE = '(max-width: 640px)';

class Card {
	constructor(el) {
		this.init(el);
	}

	init(el) {
		this.container = el;

		if (!this.container) {
			return;
		}

		const mm = window.matchMedia(MOBILE);
		const isMobile = () => mm.matches;

		this.container
			.querySelector('.card__colors-value')
			?.addEventListener('click', () => {
				if (!isMobile()) {
					this.container.classList.add('_show-colors');
				}
			});

		this.container
			.querySelector('[data-card-favorite]')
			?.addEventListener('click', (evt) => {
				evt.currentTarget.classList.toggle('_active');
			});

		const sliderContainer = this.container.querySelector('.swiper');
		const hasGrab = !this.container.classList.contains('_no-grab');

		const slider = new Swiper(sliderContainer, {
			loop: true,
			grabCursor: hasGrab,
			allowTouchMove: hasGrab,
		});

		this.container.querySelectorAll('.card__color').forEach((item, index) => {
			item.addEventListener('mouseover', () => {
				if (!isMobile()) {
					slider.slideTo(index, 0);
				}
			});
		});
	}
}

document.querySelectorAll('[data-card]').forEach((el) => new Card(el));

export default Card;
