import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import LazyAnimation from '../../assets/scripts/modules/LazyAnimation';

class Slider {
	constructor(el) {
		this.init(el);
	}

	init(el) {
		this.container = el;

		if (!this.container) {
			return;
		}

		const sliderContainer = this.container.querySelector('.swiper');
		const slidesWrapper = sliderContainer.querySelector('.swiper-wrapper');
		const slides = sliderContainer.querySelectorAll('.swiper-slide');
		const nextButton = this.container.querySelector('[data-nav-button-next]');
		const prevButton = this.container.querySelector('[data-nav-button-prev]');
		const pagination = this.container.querySelector('[data-pagination]');
		const gap = this.container.getAttribute('data-slider-gap');
		const sliderViewDesktop = this.container.getAttribute(
			'data-slider-view-desktop',
		);
		const sliderViewMobile = this.container.getAttribute(
			'data-slider-view-mobile',
		);

		if (slides.length < sliderViewDesktop * 2 || sliderViewMobile * 2) {
			slides.forEach((slide, _) => {
				const clonedSlide = slide.cloneNode(true);
				slidesWrapper.appendChild(clonedSlide);
			});
			LazyAnimation.init();
		}

		this.slider = new Swiper(sliderContainer, {
			modules: [Navigation, Pagination],
			slidesPerView: sliderViewMobile || 'auto',
			spaceBetween: 8,
			loop: true,
			grabCursor: true,
			navigation: {
				nextEl: nextButton,
				prevEl: prevButton,
			},
			pagination: {
				el: pagination,
			},

			breakpoints: {
				640: {
					slidesPerView: sliderViewDesktop || 'auto',
					spaceBetween: gap || 20,
				},
			},
			on: {
				afterInit: () => {
					this.container.classList.add('_inited');
				},
			},
		});
	}
}

document.querySelectorAll('[data-slider]').forEach((el) => new Slider(el));

export default Slider;
