const ClassName = {
	SHOWED: '_showed',
};

class ProductHeader {
	constructor() {
		this.init();
	}

	init() {
		const productInfo = document.querySelector('.product-info');
		const productHeader = document.querySelector('.product-header');

		if (!productInfo) {
			return;
		}

		window.addEventListener('scroll', () => {
			const rect = productInfo.getBoundingClientRect();
			const hasHeader = rect.top < window.innerHeight && rect.bottom < 0;
			productHeader.classList.toggle(ClassName.SHOWED, hasHeader);
		});
	}
}

export default new ProductHeader();
