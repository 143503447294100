import Popups from '../../components/popups/popups';

class SignUpOrder {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-sign-up-order]');

		if (!this.container) {
			return;
		}

		const tabsContainer = this.container.querySelector('[data-tabs]');

		Popups.onOpen.add(() => {
			if (Popups.activePopupName !== 'sign-up-order') {
				return;
			}

			tabsContainer.tabs.selectTab(1, true);
		});
	}
}

export default new SignUpOrder();
