import Swiper from 'swiper';
import Popups from '../../components/popups/popups';

class NewCertificate {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-new-certificate]');

		if (!this.container) {
			return;
		}

		this.step = 0;

		this._initBackButton();
		this._initNextButtons();
		this._initListSlider();
		this._initCertCards();

		this.toggleEmpty();
	}
	_setStep(num) {
		this.step = num;
		this.container.setAttribute('data-new-certificate', num);
		this.container.classList.remove('_empty');
	}
	_initBackButton() {
		this.backButton = this.container.querySelector(
			'[data-new-certificate-back]',
		);

		this.backButton.addEventListener('click', () => {
			switch (this.step) {
				case 0:
					Popups.close();
					break;
				case 1:
					this._setStep(0);
					break;
				case 2:
					this._setStep(0);
					break;

				default:
					break;
			}
		});
	}
	_initNextButtons() {
		this.nextButtons = this.container.querySelectorAll(
			'[data-new-certificate-way]',
		);

		this.nextButtons.forEach((button) =>
			button.addEventListener('click', () => {
				const id = button.getAttribute('data-new-certificate-way');

				switch (Number(id)) {
					case 1:
						this._setStep(1);
						break;
					case 2:
						this._setStep(2);
						break;

					default:
						break;
				}
			}),
		);
	}
	_initCertCards() {
		this.certCards = this.container.querySelectorAll(
			'.new-certificate__list .card-certificate',
		);

		this.certCards.forEach((card) => {
			card.addEventListener('click', () => {
				this._setStep(2);
			});
		});
	}
	_initListSlider() {
		this.slider = new Swiper(this.container.querySelector('.swiper'), {
			slidesPerView: 'auto',
			spaceBetween: 8,
		});
	}
	toggleEmpty() {
		this.container.classList.toggle('_empty');
	}
}

export default new NewCertificate();
