import Popups from '../../components/popups/popups';

const ClassName = {
	ACTIVE: '_active',
};

class GuideSizes {
	constructor() {
		this.init();
		window.addEventListener('resize', () => {
			this.init();
		});
	}

	init() {
		this.tableShadow();
		this.switchContent();
	}
	switchContent() {
		const guideSizes = document.querySelector('[data-guide-sizes]');

		if (!guideSizes) {
			return;
		}

		const guideSizesContainer = guideSizes.querySelectorAll(
			'[data-guide-sizes-container]',
		);
		const buttonsNext = guideSizes.querySelectorAll(
			'[data-guide-sizes-button]',
		);

		const buttonsBack = guideSizes.querySelectorAll(
			'[data-guide-sizes-button-back]',
		);

		let currentContainerIndex = 0;

		buttonsNext.forEach((button) => {
			button.addEventListener('click', () => {
				if (currentContainerIndex === 2) {
					Popups.close();
					return;
				}
				guideSizesContainer[currentContainerIndex].classList.remove(
					ClassName.ACTIVE,
				);
				guideSizesContainer[currentContainerIndex + 1].classList.add(
					ClassName.ACTIVE,
				);
				currentContainerIndex++;
			});
		});

		buttonsBack.forEach((button) => {
			button.addEventListener('click', () => {
				currentContainerIndex--;
				guideSizesContainer[currentContainerIndex + 1].classList.remove(
					ClassName.ACTIVE,
				);
				guideSizesContainer[currentContainerIndex].classList.add(
					ClassName.ACTIVE,
				);
			});
		});
	}
	tableShadow() {
		const table = document.querySelector('[data-measurements]');

		if (!table) {
			return;
		}

		const firstColumnItems = table.querySelectorAll('td:first-child');
		const secondColumnItem = table.querySelector('td:nth-child(2)');

		const handleScroll = () => {
			const fistItemWidth = firstColumnItems[0].clientWidth;
			const first =
				firstColumnItems[0].getBoundingClientRect().left + fistItemWidth;
			const second = secondColumnItem.getBoundingClientRect().left;

			table.classList.toggle('_has-shadow', second < first);
		};
		handleScroll();
		table.addEventListener('touchmove', handleScroll);
		table.addEventListener('scroll', handleScroll);
	}
}

export default new GuideSizes();
