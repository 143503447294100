const ClassName = {
	ACTIVE: '_active',
	INIT: '_init',
};

class Accordion {
	constructor(elem) {
		this.initItem(elem);
		window.addEventListener('resize', () => {
			this.updateCardHeightConst(elem);
		});
	}
	initItem(card) {
		this.updateCardHeightConst(card);
		const btn = card.querySelector('[data-accordion-btn]');
		if (!btn) {
			return;
		}
		btn.addEventListener('click', () => {
			const isActive = this.isActiveCard(card);
			if (isActive) {
				this.closeCard(card);
				return;
			}
			this.openCard(card);
		});
	}
	isActiveCard(card) {
		return card.classList.contains(ClassName.ACTIVE);
	}
	openCard(card) {
		if (this.height === 0) {
			setTimeout(() => {
				const content = card.querySelector('[data-accordion-content]');
				card.style.setProperty('--height', `${content.scrollHeight}px`);
			}, 50);
		}

		card.classList.add(ClassName.ACTIVE);
	}
	closeCard(card) {
		card.classList.remove(ClassName.ACTIVE);
	}
	updateCardHeightConst(card) {
		const isActive = this.isActiveCard(card);
		card.classList.add(ClassName.ACTIVE);
		card.classList.remove(ClassName.INIT);
		const content = card.querySelector('[data-accordion-content]');

		card.style.setProperty('--height', ``);
		card.style.setProperty('--height', `${content.scrollHeight}px`);
		this.height = content.scrollHeight;
		setTimeout(() => {
			card.classList.add(ClassName.INIT);
		}, 100);
		if (!isActive) {
			this.closeCard(card);
		}
	}
}

document
	.querySelectorAll('[data-accordion]')
	.forEach((el) => new Accordion(el));

export default Accordion;
