import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

class CardsListSlider {
	constructor(container) {
		this.init(container);
	}

	init(container) {
		this.container = container.querySelector('.swiper');
		this.nextButton = container.querySelector('[data-nav-button-next]');
		this.prevButton = container.querySelector('[data-nav-button-prev]');

		new Swiper(this.container, {
			spaceBetween: 8,
			loop: true,
			slidesPerView: 'auto',
			modules: [Navigation],
			navigation: {
				nextEl: this.nextButton,
				prevEl: this.prevButton,
			},
			breakpoints: {
				640: {
					spaceBetween: 20,
				},
			},
		});
	}
}

document
	.querySelectorAll('[data-cards-list-slider]')
	.forEach((el) => new CardsListSlider(el));

export default CardsListSlider;
