import Popups from '../../components/popups/popups';

class Delivery {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-delivery]');

		if (!this.container) {
			return;
		}

		this.step = 0;

		this._initBackButton();
		this._initNextButtons();
		this._initTotalButtons();
		this._initDeliveryWayChange();
	}
	toggleLoader() {
		this.container.classList.toggle('_loader');
	}
	_setStep(num) {
		this.step = num;
		this.container.setAttribute('data-delivery', num);
	}
	_initDeliveryWayChange() {
		this.container
			.querySelectorAll(
				'.delivery__radio-list._delivery-way input[type="radio"]',
			)
			.forEach((radio, index) =>
				radio.addEventListener('change', () => {
					this.nextButtons[0]?.setAttribute('data-delivery-way', index + 1);
				}),
			);
	}
	_initBackButton() {
		this.backButton = this.container.querySelector('[data-delivery-back]');

		this.backButton.addEventListener('click', () => {
			switch (this.step) {
				case 0:
				case 1:
					Popups.close();
					break;
				case 2:
				case 3:
				case 4:
					this.showDeliveryWays();
					break;
				case 5:
					this.showSdekList();
					break;
				case 6:
					this.showAddress();
					break;
				case 7:
					this.showStores();
					break;

				default:
					break;
			}
		});
	}
	_initNextButtons() {
		this.nextButtons = this.container.querySelectorAll('[data-delivery-way]');

		this.nextButtons.forEach((button) =>
			button.addEventListener('click', () => {
				const id = button.getAttribute('data-delivery-way');

				switch (Number(id)) {
					case 1:
						this.showSdekList();
						break;
					case 2:
						this.showAddress();
						break;
					case 3:
						this.showStores();
						break;

					default:
						break;
				}
			}),
		);
	}
	_initTotalButtons() {
		this.totalButtons = this.container.querySelectorAll(
			'[data-delivery-total]',
		);

		this.totalButtons.forEach((button) => {
			button.addEventListener('click', () => {
				const id = button.getAttribute('data-delivery-total');

				switch (Number(id)) {
					case 1:
						this.showSdekTotal();
						break;
					case 2:
						this.showAddressTotal();
						break;
					case 3:
						this.showStoresTotal();
						break;

					default:
						break;
				}
			});
		});
	}
	resetDelivery() {
		this._setStep(0);

		this.nextButtons[0].setAttribute('disabled', true);
	}
	showDeliveryWays() {
		this._setStep(1);

		this.nextButtons[0]?.removeAttribute('disabled');
	}
	showSdekList() {
		this._setStep(2);
	}
	showSdekTotal() {
		this._setStep(5);
		this.hideMapPopup();
	}
	showAddress() {
		this._setStep(3);
	}
	showAddressTotal() {
		this._setStep(6);
	}
	showStores() {
		this._setStep(4);
	}
	showStoresTotal() {
		this._setStep(7);
	}
	showMapPopup() {
		this.container.classList.add('_map-popup', '_overlay');
		const closers = this.container.querySelectorAll(
			'.delivery__map-popup-submit, .delivery__map-popup-close',
		);
		closers.forEach((closer) => {
			closer.addEventListener('click', () => this.hideMapPopup(), {
				once: true,
			});
		});
	}
	hideMapPopup() {
		this.container.classList.remove('_map-popup', '_overlay');
	}
}

export default new Delivery();
