import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

class Calendar {
	constructor() {
		this.init();
	}

	init() {
		flatpickr('[data-calendar]', {
			dateFormat: 'd.m.Y',
			inline: true,
			locale: Russian,
			onChange: () => {
				const currentData = document.querySelector('.flatpickr-day.today');
				currentData.classList.remove('today');
			},
		});
	}
}

export default new Calendar();
