const ClassName = {
	SHOWED: '_showed',
};

class Up {
	constructor() {
		this.init();
	}
	init() {
		const hero = document.querySelector('[data-hero]');
		const button = document.querySelector('[data-up]');

		if (!hero || !button) {
			return;
		}

		window.addEventListener('scroll', () => {
			const top = hero.getBoundingClientRect().top;
			button.classList.toggle(ClassName.SHOWED, top < -hero.offsetHeight);
		});
	}
}

export default new Up();
