class Counter {
	constructor(elem) {
		this.counter = elem;
		this.step = 1;

		this.refs = {
			buttonDecrement: this.counter.querySelector('[data-counter-minus]'),
			buttonIncrement: this.counter.querySelector('[data-counter-plus]'),
			value: this.counter.querySelector('[data-counter-value]'),
		};

		this.count = Number(this.refs.value.textContent) || 1;

		this.addListeners();
		this.updateCount();
	}
	updateCount() {
		this.refs.value.innerHTML = this.count;
	}
	addListeners() {
		this.refs.buttonDecrement.addEventListener(
			'click',
			this.onDecrement.bind(this),
		);
		this.refs.buttonIncrement.addEventListener(
			'click',
			this.onIncrement.bind(this),
		);
	}
	onDecrement() {
		this.count -= this.step;
		if (this.count === 1) {
			this.refs.buttonDecrement.setAttribute('disabled', true);
		}
		this.updateCount();
	}
	onIncrement() {
		this.count += this.step;
		if (this.count >= 1) {
			this.refs.buttonDecrement.removeAttribute('disabled');
		}
		this.updateCount();
	}
}

document.querySelectorAll('.counter').forEach((elem) => new Counter(elem));

export default Counter;
