const ClassName = {
	ACTIVE: '_active',
};

class FavoriteButton {
	constructor(button) {
		this.init(button);
	}

	init(button) {
		const svgIcons = button.querySelectorAll('.button__icon');
		button.addEventListener('click', () => {
			svgIcons.forEach((svgIcon) => {
				svgIcon.classList.toggle(ClassName.ACTIVE);
			});
		});
	}
}
document
	.querySelectorAll('[data-favorite-button]')
	.forEach((button) => new FavoriteButton(button));
export default FavoriteButton;
