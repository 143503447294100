const ClassName = {
	ACTIVE: '_active',
};

class SizesTooltip {
	constructor() {
		this.init();
	}

	init() {
		const container = document.querySelector('.sizes-tooltip');

		if (!container) {
			return;
		}

		const button = container.querySelector('.sizes-tooltip__button');
		const tooltip = container.querySelector('.sizes-tooltip__list');

		button.addEventListener('click', () => {
			tooltip.classList.toggle(ClassName.ACTIVE);
		});

		document.addEventListener('click', (event) => {
			if (!container.contains(event.target) && !button.contains(event.target)) {
				tooltip.classList.remove(ClassName.ACTIVE);
			}
		});
	}
}

export default new SizesTooltip();
