class Order {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-order]');

		if (!this.container) {
			return;
		}

		this._initHiddenSections();
		this._initBonus();
	}
	_initHiddenSections() {
		const toggles = this.container.querySelectorAll('[data-switcher]');

		toggles.forEach((toggle) => {
			const checkbox = toggle.querySelector('input[type="checkbox"]');
			const id = toggle.getAttribute('data-switcher');
			const section = document.querySelector(`#${id}`);

			if (!section) {
				return;
			}

			const sectionInputs = section.querySelectorAll('input');
			const onChecked = () => {
				section.hidden = !checkbox.checked;
				sectionInputs.forEach((input) => {
					input.disabled = !checkbox.checked;
				});
			};

			checkbox.addEventListener('change', onChecked);
			onChecked();
		});
	}
	_initBonus() {
		const wrapper = this.container.querySelector('.order__bonus');

		if (!wrapper) {
			return;
		}

		const bonusInput = wrapper.querySelector('[data-input]');
		const presetButtons = wrapper.querySelectorAll('[data-bonus-value]');

		presetButtons.forEach((button) => {
			button.addEventListener('click', () => {
				bonusInput?.customInput.setValue(
					Number(button.getAttribute('data-bonus-value')),
				);
			});
		});
	}
}

export default new Order();
