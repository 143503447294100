class Switcher {
	constructor(el) {
		this.init(el);
	}

	init(el) {
		this.switcherEl = el;

		if (!this.switcherEl) {
			return;
		}

		const checkbox = this.switcherEl.querySelector('input[type="checkbox"]');

		if (checkbox) {
			return;
		}

		this.switcherEl.addEventListener('click', () => {
			const isChecked = this.switcherEl.getAttribute('aria-checked') === 'true';

			this.switcherEl.setAttribute('aria-checked', !isChecked);
		});
	}
}

document.querySelectorAll('[data-switcher]').forEach((el) => new Switcher(el));

export default new Switcher();
