const HTML_CLASSLIST = document.documentElement.classList;

const ClassName = {
	SCROLLED: '_scrolled',
};

class Header {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-header]');

		if (!this.container) {
			return;
		}

		window.addEventListener('scroll', this._onWindowScroll.bind(this));
		this._onWindowScroll();
	}
	_onWindowScroll() {
		HTML_CLASSLIST.toggle(ClassName.SCROLLED, window.scrollY > 0);
	}
}

export default new Header();
