class Cases {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-cases]');

		if (!this.container) {
			return;
		}

		const sliderEl = this.container.querySelector('[data-collection-slider]');
		const swiper = sliderEl.slider.slider;
		const button = this.container.querySelector('[data-id]');

		button &&
			swiper.on('slideChange', (swiper) => {
				const id =
					swiper.slides[swiper.activeIndex].getAttribute('data-case-id');

				if (id) {
					button.setAttribute('data-id', id);
				}
			});
	}
}

export default new Cases();
