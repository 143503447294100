import customSelect from 'custom-select';

class Dropdown {
	constructor(el) {
		this.init(el);
	}
	init(el) {
		const container = el;
		el.dropdown = this;

		const selectEl = container.querySelector('select');
		this.cstSel = customSelect(selectEl)[0];
		this.options = Array.from(this.cstSel.select.options);

		container.customSelect = this.cstSel;
		const cstOptions = this.cstSel.panel.querySelectorAll(
			'.custom-select-option',
		);

		const optIndex = this.options.findIndex(
			(option) => option.value === this.cstSel.value,
		);

		this.setValue(
			this.cstSel.value,
			this.options[optIndex].getAttribute('data-placeholder'),
		);

		this.options.forEach((option, index) => {
			const dataSort = option.getAttribute('data-sort');
			const dataPlaceholder = option.getAttribute('data-placeholder');

			cstOptions[index]?.classList.toggle('is-disabled', option.disabled);

			dataSort && cstOptions[index]?.setAttribute('data-sort', dataSort);
			dataPlaceholder &&
				cstOptions[index]?.setAttribute('data-placeholder', dataPlaceholder);
		});

		this.cstSel.select.addEventListener('change', () => {
			const optIndex = this.options.findIndex(
				(option) => option.value === this.cstSel.value,
			);

			this.setValue(
				this.cstSel.value,
				this.options[optIndex].getAttribute('data-placeholder'),
			);
		});
	}
	setValue(value, placeholder) {
		if (value) {
			this.cstSel.value = value;

			if (placeholder) {
				this.cstSel.opener.innerHTML = `<span>${placeholder}</span>`;
				setTimeout(() => {
					this.cstSel.opener.innerHTML = `<span>${placeholder}</span>`;
				}, 100);
			} else {
				this.cstSel.opener.innerHTML = `<span>${value}</span>`;
			}
		}
	}
}

document.querySelectorAll('[data-dropdown]').forEach((el) => new Dropdown(el));

export default Dropdown;
