import Popups from '../../components/popups/popups';

const ClassName = {
	ACTIVE: '_active',
	ANIMATING: '_animating',
};

const MOBILE = '(max-width: 640px)';

class Menu {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-menu]');

		if (!this.container) {
			return;
		}

		this._initContent();
		this._initAccordions();
		this._initShowMore();
		this._initOpeners();
		this._initTabbar();

		const toggles = document.querySelectorAll('[data-menu-toggle]');

		this.opened = Popups.activePopupName === 'menu';

		toggles.forEach((toggle) => {
			toggle.addEventListener('click', () => {
				if (Popups.animating) {
					return;
				}

				this.opened ? Popups.close() : Popups.open('menu');
			});
		});

		Popups.onOpen.add(() => {
			if (Popups.activePopupName !== 'menu') {
				return;
			}

			this.opened = true;
			toggles.forEach((toggle) => {
				toggle.setAttribute('aria-expanded', this.opened);
			});

			const mobileSection = this.container.querySelector(
				`[data-menu-mobile-section="${this.openerId}"]`,
			);

			if (this.openerId) {
				this._updateActive(this.openerId);
				this.openerId = null;

				setTimeout(() => {
					if (this.isMobile() && mobileSection) {
						mobileSection?.scrollIntoView({ behavior: 'smooth' });
						return;
					}

					this._showContent();
				}, 400);
			}

			if (!this.catalogItem || !this.activeTabbarItem) return;

			this.activeTabbarItem.removeAttribute('aria-current');
			this.catalogItem.setAttribute('aria-current', 'page');
		});
		Popups.onCloseStart.add(() => {
			if (Popups.activePopupName !== 'menu') {
				return;
			}

			this.opened = false;
			toggles.forEach((toggle) => {
				toggle.setAttribute('aria-expanded', this.opened);
			});
			this._hideContent();

			if (!this.catalogItem || !this.activeTabbarItem) return;

			this.catalogItem.removeAttribute('aria-current');
			this.activeTabbarItem.setAttribute('aria-current', 'page');
		});
	}
	_initContent() {
		const items = this.container.querySelectorAll('.menu__item-toggle');
		const backButton = this.container.querySelector('[data-menu-back]');
		this.contentWrapper = this.container.querySelector('.menu__col._right');
		this.activeId = null;
		this.mobileSectionsIds = Array.from(
			this.container.querySelectorAll('[data-menu-mobile-section]'),
		).map((el) => el.getAttribute('data-menu-mobile-section'));

		this.matchMedia = window.matchMedia(MOBILE);
		this.isMobile = () => this.matchMedia.matches;

		const onMMChange = (e) => {
			if (e.matches) {
				this.contentWrapper.removeAttribute('style');
				items.forEach((item) => {
					item.handler && item.removeEventListener('mouseenter', item.handler);
					item.handler = () => this._toggleContent(item);
					item.addEventListener('click', item.handler);
				});
			} else {
				items.forEach((item) => {
					item.handler && item.removeEventListener('click', item.handler);
					item.handler = () => this._toggleContent(item);
					item.addEventListener('mouseenter', item.handler);
				});
			}

			if (this.activeId) {
				this._hideContent();
			}
		};

		this.matchMedia.addEventListener('change', onMMChange);
		onMMChange(this.matchMedia);

		backButton.addEventListener('click', () => {
			this._hideContent();
		});
	}
	_showContent(immediate = false) {
		this.container.classList.add(ClassName.ACTIVE);
		this.activeItem?.classList.add(ClassName.ACTIVE);
		if (this.isMobile() || immediate) {
			this.container.classList.add(ClassName.ACTIVE);
			this.activeSection?.classList.add(ClassName.ACTIVE);
			this.activeTitle?.classList.add(ClassName.ACTIVE);
			this.activeSection?.classList.remove(ClassName.ANIMATING);
			return;
		}

		const content = this.contentWrapper;

		this.contentAnimating = true;
		this.activeSection?.classList.add(ClassName.ANIMATING);
		content.style.width = `${content.scrollWidth}px`;
		clearTimeout(content.TO);
		content.TO = setTimeout(() => {
			content.style.width = 'max-content';
			this.activeSection?.classList.remove(ClassName.ANIMATING);
			this.activeSection?.classList.add(ClassName.ACTIVE);
			this.contentAnimating = false;
		}, 300);
	}
	_hideContent(immediate = false) {
		this.container.classList.remove(ClassName.ACTIVE);
		this.activeItem?.classList.remove(ClassName.ACTIVE);
		this.activeId = null;

		if (this.isMobile() || immediate) {
			this.activeTitle?.classList.remove(ClassName.ACTIVE);
			this.activeSection?.classList.remove(ClassName.ACTIVE);
			this.activeSection?.classList.remove(ClassName.ANIMATING);
			return;
		}

		const content = this.contentWrapper;

		this.contentAnimating = true;
		this.activeSection?.classList.add(ClassName.ANIMATING);
		content.style.width = `${content.scrollWidth}px`;
		setTimeout(() => {
			content.style.width = 0;
		}, 100);
		clearTimeout(content.TO);
		content.TO = setTimeout(() => {
			this.contentAnimating = false;
			this.activeSection?.classList.remove(ClassName.ANIMATING);
			this.activeSection?.classList.remove(ClassName.ACTIVE);
		}, 300);
	}
	_toggleContent(item) {
		const id = item.getAttribute('data-menu-item');

		if (!id) {
			this.activeId && this._hideContent();
			return;
		}

		if (this.activeId !== id || this.contentAnimating) {
			if (this.activeId && this.activeId !== id) {
				this._hideContent(true);
				this._updateActive(id);
				this._showContent(true);
				return;
			}

			this._hideContent(true);
		}

		this._updateActive(id);
		this._showContent();
	}
	_initShowMore() {
		const showMoreItems = this.container.querySelectorAll('._show-more');

		showMoreItems.forEach((content) => {
			const toggle = content.nextElementSibling;

			toggle?.addEventListener('click', (evt) => {
				evt.preventDefault();

				const isExpanded = toggle.getAttribute('aria-expanded') === 'true';
				const toggleText = toggle.querySelector('span');

				if (!toggle.showText && !toggle.hideText) {
					toggle.showText = toggleText.textContent;
					toggle.hideText = toggle.getAttribute('data-menu-show-more');
				}

				toggleText.textContent = isExpanded ? toggle.showText : toggle.hideText;
				content.classList.toggle('_active', !isExpanded);
				toggle.setAttribute('aria-expanded', !isExpanded);
			});
		});
	}
	_initAccordions() {
		const toggles = this.container.querySelectorAll('.menu__accordion-toggle');

		toggles.forEach((toggle) => {
			toggle.addEventListener('click', () => {
				toggle.classList.toggle('_active');
			});
		});
	}
	_updateActive(id) {
		this.activeId = id;
		this.activeSection = this.container.querySelector(
			`[data-menu-content=${id}]`,
		);
		this.activeTitle = this.container.querySelector(`#${id}`);
		this.activeItem = this.container.querySelector(`[data-menu-item=${id}]`);
	}
	_initOpeners() {
		const buttons = document.querySelectorAll('[data-menu-opener]');

		buttons.forEach((button) => {
			button.addEventListener('click', () => {
				this.openerId = button.getAttribute('data-menu-opener');
				this.openerId && Popups.open('menu');
			});
		});
	}
	_initTabbar() {
		const tabbarEl = document.querySelector('.tabbar');

		if (!tabbarEl) {
			return;
		}

		this.catalogItem = tabbarEl.querySelector('[href="/#menu"]');
		this.catalogItem.addEventListener('click', () => {
			if (window.location.pathname !== '/') {
				return;
			}

			Popups.open('menu');
		});
		this.activeTabbarItem = tabbarEl.querySelector('[aria-current="page"]');
	}
}

export default new Menu();
