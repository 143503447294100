const ClassName = {
	ACTIVE: '_active',
};

class CartItem {
	constructor(el) {
		this.init(el);
	}

	init(el) {
		this.container = el;
		el.cartItem = this;

		this.checkbox = this.container.querySelector('.cart-item__checkbox input');
		this.mobileSizeText = this.container.querySelector(
			'.cart-item__size-value',
		);
		this.desktopSizeSelect = this.container.querySelector('.cart-item__size');

		this._initMobileSizeSelect();
		this._initPopups();

		this.updateSize(this.mobileSizeText.textContent || null);
		this.selected = this.checkbox.checked;
		this.checkbox.addEventListener('change', () => {
			this.selected = this.checkbox.checked;
		});
	}
	updateSize(val) {
		this.size = val;
		this.desktopSizeSelect.dropdown.setValue(val);
		this.mobileSizeText.textContent = val;
	}
	_initMobileSizeSelect() {
		const sizePopup = this.container.querySelector(
			'[data-cart-item-popup="size"]',
		);

		if (!sizePopup) {
			return;
		}

		const sizeRadioInputs = sizePopup.querySelectorAll('.size-radio input');
		const sizeButton = sizePopup.querySelector(
			'.cart-item__popup-submit._size',
		);
		const sizeButtonText = sizeButton.querySelector('.size-value');
		const changeSize = (val) => {
			sizeButtonText.textContent = val;
			this.updateSize(val);
		};
		sizeRadioInputs.forEach((input) => {
			input.checked && changeSize(input.value);
			input.addEventListener('change', () => changeSize(input.value));
		});
		sizeButton.addEventListener('click', () => {
			this.closePopup();
		});
	}
	_initPopups() {
		this.popupsContainer = this.container.querySelector('.cart-item__popups');
		this.activePopup = null;

		if (!this.popupsContainer) {
			return;
		}

		this.popupsContainer.addEventListener('click', (evt) => {
			if (evt.target === this.popupsContainer) {
				this.closePopup();
			}
		});

		this.container
			.querySelectorAll('[data-cart-item-popup-opener]')
			.forEach((opener) => {
				opener.addEventListener('click', () => {
					const popupName = opener.getAttribute('data-cart-item-popup-opener');
					this.openPopup(popupName);
				});
			});
	}
	openPopup(name) {
		this.activePopup?.classList.remove(ClassName.ACTIVE);
		this.activePopup = this.container.querySelector(
			`[data-cart-item-popup="${name}"]`,
		);
		this.activePopup?.classList.add(ClassName.ACTIVE);
		this.popupsContainer.classList.add(ClassName.ACTIVE);

		this.activePopup
			.querySelectorAll('[data-cart-item-popup-closer]')
			.forEach((closer) =>
				closer.addEventListener('click', () => this.closePopup(), {
					once: true,
				}),
			);
	}
	closePopup() {
		this.activePopup?.classList.remove(ClassName.ACTIVE);
		this.activePopup = null;
		setTimeout(() => {
			this.popupsContainer.classList.remove(ClassName.ACTIVE);
		}, 300);
	}
}

document.querySelectorAll('[data-cart-item]').forEach((el) => new CartItem(el));

export default CartItem;
