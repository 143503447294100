import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const ClassName = {
	LIMITED: '_limited',
	SHOWED: '_showed',
	ACTIVE: '_active',
};

class ProductInfo {
	constructor() {
		this.sliderInstances = new Map();
		this.init();
	}

	init() {
		const container = document.querySelector('.product-info');

		if (!container) {
			return;
		}

		this.id = 1;
		const gallerys = container.querySelectorAll('[data-product-gallery]');
		const galleryWrapper = container.querySelector(
			'[data-product-gallery-wrapper]',
		);
		const previews = container.querySelectorAll('[data-product-preview-slide]');

		const initSlider = (swiperContainer) => {
			if (this.sliderInstances.has(swiperContainer)) {
				return;
			}

			const galleryWrapper = swiperContainer.querySelector(
				'[data-product-gallery-wrapper]',
			);
			const nextButton = swiperContainer.querySelector(
				'[data-nav-button-next]',
			);
			const prevButton = swiperContainer.querySelector(
				'[data-nav-button-prev]',
			);
			const pagination = swiperContainer.querySelector('.swiper-pagination');
			let slides = swiperContainer.querySelectorAll('.swiper-slide');

			if (!swiperContainer.classList.contains('slides-cloned')) {
				slides = swiperContainer.querySelectorAll('.swiper-slide');

				if (slides.length < 3) {
					const slidesToDuplicate = Array.from(slides);
					while (slides.length < 4) {
						slidesToDuplicate.forEach((slide) => {
							if (slides.length >= 4) return;
							const clonedSlide = slide.cloneNode(true);
							galleryWrapper.appendChild(clonedSlide);
							slides = swiperContainer.querySelectorAll('.swiper-slide');
						});
					}
					swiperContainer.classList.add('slides-cloned');
				}
			}

			const slider = new Swiper(swiperContainer, {
				spaceBetween: 10,
				slidesPerView: 'auto',
				centeredSlides: true,
				loop: true,
				modules: [Navigation, Pagination],
				navigation: {
					nextEl: nextButton,
					prevEl: prevButton,
				},
				pagination: {
					el: pagination,
				},
			});

			this.sliderInstances.set(swiperContainer, slider);
		};

		const initialGallery = container.querySelector(
			'[data-product-gallery]._active',
		);
		const swiperSlider = initialGallery.querySelector('.swiper');
		initSlider(swiperSlider);

		previews.forEach((preview) => {
			preview.addEventListener('click', () => {
				this.id = preview.dataset.productPreviewSlide;

				previews.forEach((prev) => prev.classList.remove(ClassName.ACTIVE));
				document
					.querySelector(`[data-product-preview-slide="${this.id}"]`)
					.classList.add(ClassName.ACTIVE);

				gallerys.forEach((gallery) => {
					gallery.classList.remove(ClassName.ACTIVE);
				});
				const activeGallery = gallerys[this.id - 1];
				activeGallery.classList.add(ClassName.ACTIVE);

				const newSwiperSlider = activeGallery.querySelector('.swiper');
				initSlider(newSwiperSlider);

				galleryWrapper.dataset.numberImage = this.id;
			});
		});

		this.showAllPreviews(container);
	}

	showAllPreviews(container) {
		const button = container.querySelector('[data-show-preview]');
		if (!button) return;

		const textElement = button.querySelector('span');
		const previewContainer = container.querySelector('[data-product-preview]');

		const showText = textElement.textContent;
		const hideText = button.dataset.hideText;

		button.addEventListener('click', () => {
			textElement.textContent =
				textElement.textContent === showText ? hideText : showText;
			previewContainer.classList.toggle(ClassName.LIMITED);
			button.classList.toggle(ClassName.SHOWED);
		});
	}
}

export default new ProductInfo();
