import Popups from '../../components/popups/popups';

const ClassName = {
	ACTIVE: '_active',
	HIDDEN: 'hidden',
};

class PayMethods {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-pay-methods]');

		if (!this.container) {
			return;
		}

		this.step = 0;
		this.items = this.container.querySelectorAll('.pay-methods__item');
		this.nextButtons = this.container.querySelectorAll(
			'[data-pay-methods-step]',
		);
		this.backButton = this.container.querySelector('[data-pay-methods-back]');

		this._initCompanyInput();

		this.items.forEach((el) => {
			const input = el.querySelector('input');
			const isCashless = el.getAttribute('data-pay-method') === 'cashless';

			if (isCashless) {
				this.cashlessItem = el;
			}
			input.addEventListener('change', () => {
				if (isCashless) {
					if (this.hasCompany) {
						this._setStep(3);
						return;
					}

					this._setStep(1);
					return;
				}
				this._setStep(0);
			});
		});

		this.nextButtons.forEach((button) =>
			button.addEventListener('click', () => {
				this._setStep(button.getAttribute('data-pay-methods-step'));
			}),
		);

		this.backButton.addEventListener('click', () => {
			switch (Number(this.step)) {
				case 0:
				case 1:
				case 3:
					Popups.close();
					break;
				case 2:
					if (this.hasCompany) {
						this._setStep(3);
						break;
					}
				case 4:
					this._setStep(3);
					break;
				case 5:
					this._setStep(4);
					break;

				default:
					break;
			}
		});
	}
	_setStep(num) {
		this.step = num;
		this.container.setAttribute('data-pay-methods', num);
	}
	_initCompanyInput() {
		this.companyWrapper = this.container.querySelector('.pay-methods__company');
		this.companySubmit = this.container.querySelector(
			'.pay-methods__company-submit',
		);

		if (!this.companyWrapper || !this.companySubmit) {
			return;
		}

		const inputContainer = this.container.querySelector(
			'.pay-methods__company-input',
		);
		const hints = inputContainer.querySelectorAll('[data-input-hint-value]');
		hints.forEach((hint) => {
			hint.addEventListener('click', () => {
				this.showCompany();
			});
		});
	}
	showCompany() {
		this.companyWrapper.classList.remove(ClassName.HIDDEN);
		this.cashlessItem.classList.add(ClassName.ACTIVE);
		this.companySubmit.removeAttribute('disabled');
		this.hasCompany = true;
	}
	hideCompany() {
		this.companyWrapper.classList.add(ClassName.HIDDEN);
		this.cashlessItem.classList.remove(ClassName.ACTIVE);
		this.companySubmit.setAttribute('disabled', true);
		this.hasCompany = false;
	}
}

export default new PayMethods();
