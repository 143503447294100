const ClassName = {
	ACTIVE: '_active',
};

class SizesTooltip {
	constructor(container) {
		this.container = container;
		this.button = this.container.querySelector('.sizes-tooltip__button');
		this.tooltip = this.container.querySelector('.sizes-tooltip__list');
		this.init();
	}

	init() {
		if (!this.container || !this.button || !this.tooltip) {
			return;
		}

		this.button.addEventListener('click', () => {
			this.tooltip.classList.toggle(ClassName.ACTIVE);
		});

		document.addEventListener('click', (event) => {
			if (
				!this.container.contains(event.target) &&
				!this.button.contains(event.target)
			) {
				this.tooltip.classList.remove(ClassName.ACTIVE);
			}
		});
	}
}

const containers = document.querySelectorAll('.sizes-tooltip');
containers.forEach((container) => {
	new SizesTooltip(container);
});

export default SizesTooltip;
