const ClassName = {
	ACTIVE: '_active',
};

class OrderCertificates {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-order-certificates]');

		if (!this.container) {
			return;
		}

		let currentStepIndex = 0;
		const button = this.container.querySelector(
			'[data-order-certificates-button]',
		);
		const steps = this.container.querySelectorAll(
			'[data-order-certificates-step]',
		);
		const buttonsPrev = this.container.querySelectorAll(
			'[data-popup-prev-step]',
		);

		button.addEventListener('click', () => {
			if (currentStepIndex === steps.length - 1) {
				window.location.href =
					steps[currentStepIndex].dataset.orderCertificatesLink;
				return;
			}

			steps[currentStepIndex].classList.remove(ClassName.ACTIVE);
			currentStepIndex++;
			steps[currentStepIndex].classList.add(ClassName.ACTIVE);

			button.querySelector('span').innerHTML = steps[
				currentStepIndex
			].getAttribute('data-order-certificates-button-text');
		});

		buttonsPrev.forEach((btn) => {
			btn.addEventListener('click', () => {
				steps[currentStepIndex].classList.remove(ClassName.ACTIVE);
				currentStepIndex--;
				steps[currentStepIndex].classList.add(ClassName.ACTIVE);

				button.querySelector('span').innerHTML = steps[
					currentStepIndex
				].getAttribute('data-order-certificates-button-text');
			});
		});

		const buttonsChange = document.querySelectorAll(
			'[data-order-certificates-change]',
		);

		buttonsChange.forEach((button) => {
			button.addEventListener('click', () => {
				const id = button.getAttribute('data-order-certificates-change');

				steps[currentStepIndex].classList.remove(ClassName.ACTIVE);
				currentStepIndex = Number(id) - 1;
				steps[currentStepIndex].classList.add(ClassName.ACTIVE);
			});
		});
	}
}

export default new OrderCertificates();
