const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

class Gift {
	constructor() {
		this.init();
	}
	init() {
		const form = document.querySelector('[data-gift-form]');
		if (!form) {
			return;
		}

		const email = form.querySelector('[name="email"]');
		const button = form.querySelector('[data-gift-form-submit]');

		email.addEventListener('input', (evt) => {
			const isValidEmail = emailRegex.test(evt.target.value);

			if (isValidEmail) {
				button.removeAttribute('disabled');
			} else {
				button.setAttribute('disabled', 'true');
			}
		});
	}
}

export default new Gift();
