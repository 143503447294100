import { gsap } from 'gsap';

class Footer {
	_progress = { target: 0, current: 0 };

	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-footer-bottom]');
		this.tabbar = document.querySelector('[data-tabbar');
		this.sun = document.querySelector('[data-sun]');

		if (!this.container || !this.sun) {
			return;
		}

		this._initSunAnimation();
	}
	_initSunAnimation() {
		window.addEventListener('scroll', () => this._handleUpdate());
		window.addEventListener('resize', () => this._handleUpdate());
	}

	_handleUpdate() {
		const { container, sun, tabbar } = this;

		const vHeight = window.innerHeight;
		const containerBounding = container.getBoundingClientRect();
		const tabbarOffset = tabbar?.offsetHeight ?? 0;
		const startOffset = sun.offsetHeight + sun.offsetHeight;

		const thresholdBottom = vHeight - tabbarOffset - startOffset;
		const thresholdTop = vHeight - tabbarOffset - containerBounding.height;

		let scrollProgress =
			(containerBounding.top - thresholdBottom) /
			(thresholdTop - thresholdBottom);
		scrollProgress = Math.max(scrollProgress, 0);

		if (this._progress.target === scrollProgress) {
			return;
		}

		this._progress.target = scrollProgress;

		gsap.to(this._progress, {
			current: scrollProgress,
			duration: 0.3,
			onUpdate: () => this._render(),
		});
	}

	_render() {
		const start = 0.2;
		const end = 1;

		const current = start + (end - start) * this._progress.current;

		this.sun.style.setProperty('--ray-progress', current);
	}
}

export default new Footer();
