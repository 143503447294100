import { gsap } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

// window.gsap = gsap;

// gsap.defaults({
// 	overwrite: 'auto',
// });

const HTML_CLASSLIST = document.documentElement.classList;

class HircostApp {
	constructor() {
		this.env = require('./utils/env').default;
		// this.utils = require('./utils/utils').default;
		this.classes = {};
		this.modules = {
			ScrollTo: require('./modules/ScrollTo').default,
			BackPage: require('./modules/BackPage').default,
			LazyAnimation: require('./modules/LazyAnimation').default,
			Validation: require('./modules/Validation').default,
		};
		this.components = {
			Header: require('../../components/header/header').default,
			Counter: require('../../components/counter/counter').default,
			Popups: require('../../components/popups/popups').default,
			Card: require('../../components/card/card').default,
			Slider: require('../../components/slider/slider').default,
			Tabs: require('../../components/tabs/tabs').default,
			Accordion: require('../../components/accordion/accordion').default,
			Dropdown: require('../../components/dropdown/dropdown').default,
			Input: require('../../components/input/input').default,
			CartItem: require('../../components/cart-item/cart-item').default,
			ReadMore: require('../../components/read-more/read-more').default,
			CollectionSlider:
				require('../../components/collection-slider/collection-slider').default,
			CardsListSlider:
				require('../../components/cards-list-slider/cards-list-slider').default,
			CatalogList: require('../../components/catalog-list/catalog-list')
				.default,
			MainHero: require('../../includes/main-hero/main-hero').default,
			ProductInfo: require('../../includes/product-info/product-info').default,
			ProductFullscreen: require('../../includes/popups/product-fullscreen')
				.default,
			CaseForm: require('../../includes/popups/case-form').default,
			CustomScroll: require('../../includes/custom-scroll/custom-scroll')
				.default,
			GuideSizes: require('../../includes/popups/guide-sizes').default,
			SizesTooltip: require('../../includes/sizes-tooltip/sizes-tooltip')
				.default,
			ProductHeader: require('../../includes/product-header/product-header')
				.default,
			Filter: require('../../includes/popups/filter').default,
			ShowInformation:
				require('../../components/show-information/show-information').default,
			FavoriteButton: require('../../includes/favorite-button/favorite-button')
				.default,
			Gift: require('../../includes/popups/gift').default,
			Switcher: require('../../components/switcher/switcher').default,
			Menu: require('../../includes/popups/menu').default,
			Up: require('../../includes/up/up').default,
			Toast: require('../../components/toast/toast').default,
			SignUpOrder: require('../../includes/popups/sign-up-order').default,
			Delivery: require('../../includes/popups/delivery').default,
			Order: require('../../includes/order/order').default,
			SignIn: require('../../includes/popups/sign-in').default,
			SearchMenu: require('../../includes/search-menu/search-menu').default,
			ShowMore: require('../../components/show-more/show-more').default,
			OrderCertificates: require('../../includes/popups/order-certificates')
				.default,
			Buyers: require('../../includes/buyers/buyers').default,
			Calendar: require('../../includes/calendar/calendar').default,
			PayMethods: require('../../includes/popups/pay-methods').default,
			Footer: require('../../components/footer/footer').default,
			Cases: require('../../includes/cases/cases').default,
			NewCertificate: require('../../includes/popups/new-certificate').default,
		};
		this.helpers = {
			GrabScroll: require('./helpers/GrabScroll').default,
		};

		window.addEventListener('load', () => {
			HTML_CLASSLIST.remove('_loading');

			const hash = window.location.hash;
			if (hash) {
				const popupName = hash.substring(1);
				const popup = this.components.Popups.getPopup(popupName);

				if (popup) {
					this.components.Popups.open(popupName);
				}
			}

			document.documentElement.style.setProperty(
				'--scrollbar-width',
				`${window.innerWidth - document.body.clientWidth}px`,
			);
		});
	}
}

window.HircostApp = new HircostApp();
