import Swiper from 'swiper';
import { FreeMode, Thumbs, Mousewheel, Pagination } from 'swiper/modules';

class ProductFullscreen {
	constructor(container) {
		this.container = container;
		this.init();
	}

	init() {
		if (!this.container) {
			return;
		}

		this.gallery = this.container.querySelector(
			'[data-product-fullscreen-gallery]',
		);
		this.preview = this.container.querySelector(
			'[data-product-fullscreen-preview]',
		);
		const paginationElement =
			this.container.querySelector('.swiper-pagination');

		const sliderPreview = new Swiper(this.preview, {
			spaceBetween: 8,
			direction: 'vertical',
			slidesPerView: 'auto',
			freeMode: true,
			watchSlidesProgress: true,
		});

		new Swiper(this.gallery, {
			slidesPerView: 'auto',
			height: window.innerHeight,
			modules: [FreeMode, Thumbs, Mousewheel, Pagination],
			mousewheel: {
				invert: false,
			},
			thumbs: {
				swiper: sliderPreview,
			},
			freeMode: false,
			pagination: {
				el: paginationElement,
			},
			breakpoints: {
				640: {
					direction: 'vertical',
					freeMode: {
						enabled: true,
						sticky: false,
					},
				},
			},
		});
	}
}

const containers = document.querySelectorAll('[data-product-fullscreen]');

containers.forEach((container) => {
	new ProductFullscreen(container);
});

export default ProductFullscreen;
