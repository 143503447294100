const ClassName = {
	HIDDEN: '_hidden',
	SELECTED: '_selected',
};

class Buyers {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-buyers]');

		if (!this.container) {
			return;
		}

		this.checkUrlHash();

		window.addEventListener('popstate', () => {
			this.checkUrlHash();
		});

		const tabLinks = this.container.querySelectorAll('.buyers__tab');
		tabLinks.forEach((tabLink) => {
			tabLink.addEventListener('click', (e) => {
				e.preventDefault();
				const href = tabLink.getAttribute('href');
				if (href) {
					const targetTab = href.slice(1);
					window.location.hash = targetTab;
					this.activateTab(targetTab);
				}
			});
		});
	}

	activateTab(tabId) {
		const tabLinks = this.container.querySelectorAll('.buyers__tab');
		const tabGroups = this.container.querySelectorAll('.buyers__tab-group');

		tabLinks.forEach((tabLink, index) => {
			const href = tabLink.getAttribute('href');
			if (href) {
				const targetTab = href.slice(1);
				if (targetTab === tabId) {
					tabLink.classList.add(ClassName.SELECTED);
					tabGroups[index].classList.remove(ClassName.HIDDEN);
				} else {
					tabLink.classList.remove(ClassName.SELECTED);
					tabGroups[index].classList.add(ClassName.HIDDEN);
				}
			}
		});
	}

	checkUrlHash() {
		const hash = window.location.hash.slice(1);
		if (hash) {
			this.activateTab(hash);
		}
	}
}

export default new Buyers();
