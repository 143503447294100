import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import productFullscreen from '../popups/product-fullscreen';

const ClassName = {
	LIMITED: '_limited',
	SHOWED: '_showed',
	ACTIVE: '_active',
};
class ProductInfo {
	constructor() {
		this.init();
	}

	init() {
		const container = document.querySelector('.product-info');

		if (!container) {
			return;
		}

		this.id = 1;
		const gallery = container.querySelector('[data-product-gallery]');
		const galleryWrapper = container.querySelector(
			'[data-product-gallery-wrapper]',
		);
		const nextButton = container.querySelector('[data-nav-button-next]');
		const prevButton = container.querySelector('[data-nav-button-prev]');
		const pagination = container.querySelector('.swiper-pagination');
		const previews = container.querySelectorAll('[data-product-preview-slide]');

		new Swiper(gallery, {
			spaceBetween: 10,
			slidesPerView: 'auto',
			centeredSlides: true,
			loop: true,
			modules: [Navigation, Pagination],
			navigation: {
				nextEl: nextButton,
				prevEl: prevButton,
			},
			pagination: {
				el: pagination,
			},
		});

		previews.forEach((preview) => {
			preview.addEventListener('click', () => {
				this.id = preview.dataset.productPreviewSlide;

				previews.forEach((prev) => prev.classList.remove(ClassName.ACTIVE));
				document
					.querySelector(`[data-product-preview-slide="${this.id}"]`)
					.classList.add(ClassName.ACTIVE);

				const slidesGallery = gallery.querySelectorAll('.swiper-slide');

				slidesGallery.forEach((slide, index) => {
					slide.innerHTML = `<img src="/assets/images/product-info/product-gallery-${this.id}-${index + 1}.jpg" alt="">`;
				});

				galleryWrapper.dataset.numberImage = this.id;
			});
		});

		galleryWrapper.addEventListener('click', (e) => {
			const target = e.currentTarget;
			let id = target.dataset.numberImage || 1;

			productFullscreen.changeImages(id);
		});

		this.showAllPreviews(container);
	}
	showAllPreviews(container) {
		const button = container.querySelector('[data-show-preview]');
		if (!button) return;

		const textElement = button.querySelector('span');
		const previewContainer = container.querySelector('[data-product-preview]');

		const showText = textElement.textContent;
		const hideText = button.dataset.hideText;

		button.addEventListener('click', () => {
			textElement.textContent =
				textElement.textContent === showText ? hideText : showText;
			previewContainer.classList.toggle(ClassName.LIMITED);
			button.classList.toggle(ClassName.SHOWED);
		});
	}
}

export default new ProductInfo();
