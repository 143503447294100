import Swiper from 'swiper';

class Card {
	constructor(el) {
		this.init(el);
	}

	init(el) {
		this.container = el;

		if (!this.container) {
			return;
		}

		this.container
			.querySelector('[data-card-favorite]')
			?.addEventListener('click', (evt) => {
				evt.currentTarget.classList.toggle('_active');
			});

		const sliderContainer = this.container.querySelector('.swiper');

		new Swiper(sliderContainer, {
			loop: true,
			grabCursor: true,
		});
	}
}

document.querySelectorAll('[data-card]').forEach((el) => new Card(el));

export default Card;
