import PageScroll from '../../assets/scripts/helpers/Scroll';
import Popups from '../../components/popups/popups';

const HTML_CLASSLIST = document.documentElement.classList;
const ClassName = {
	OPENED: '_search-menu-opened',
	HIDDEN: 'hidden',
};

class SearchMenu {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-search-menu]');

		if (!this.container) {
			return;
		}

		this.opened = false;
		this.resultsWrapper = this.container.querySelector('.search-menu__result');
		this.tagsEl = this.container.querySelector('.search-menu__tags');
		this.errorTextEl = this.container.querySelector(
			'.search-menu__text._error',
		);

		const toggles = document.querySelectorAll('[data-search-menu-toggle]');
		const closers = document.querySelectorAll('[data-search-menu-closer]');

		toggles.forEach((toggle) => {
			toggle.addEventListener('click', () => {
				this.opened = !this.opened;

				this.opened ? this.open() : this.close();
			});
		});

		closers.forEach((closer) => {
			closer.addEventListener('click', () => {
				this.close();
			});
		});

		window.addEventListener('keydown', (e) => {
			if (this.opened && e.key === 'Escape') {
				this.close();
			}
		});
	}

	open() {
		this.opened = true;

		Popups.close();
		HTML_CLASSLIST.add(ClassName.OPENED);
		PageScroll.disabled(this.container);
	}
	close() {
		this.opened = false;
		HTML_CLASSLIST.remove(ClassName.OPENED);
		PageScroll.enabled(this.container);
	}
	showResults() {
		this.resultsWrapper.classList.remove(ClassName.HIDDEN);
	}
	hideResults() {
		this.resultsWrapper.classList.add(ClassName.HIDDEN);
	}
	showTags() {
		this.tagsEl.classList.remove(ClassName.HIDDEN);
	}
	hideTags() {
		this.tagsEl.classList.add(ClassName.HIDDEN);
	}
	showError() {
		this.errorTextEl.classList.remove(ClassName.HIDDEN);
	}
	hideError() {
		this.errorTextEl.classList.add(ClassName.HIDDEN);
	}
}

export default new SearchMenu();
