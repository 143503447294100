class CaseForm {
	constructor(el) {
		this.init(el);
	}

	init(el) {
		this.container = el;

		if (!this.container) {
			return;
		}

		const form = this.container.querySelector('.case-form__form');

		form.addEventListener('submit', (evt) => {
			evt.preventDefault();

			const cartItems = Array.from(
				this.container.querySelectorAll('[data-cart-item]'),
			).map((el) => el.cartItem);

			cartItems
				.filter((item) => item.selected)
				.forEach((item) => {
					if (!item.size) {
						item.openPopup('size');
						this.container.classList.add('_cart');
					}
				});
		});
	}
}

document.querySelectorAll('[data-case-form]').forEach((el) => new CaseForm(el));

export default CaseForm;
