import IMask from 'imask';
import Popups from '../../components/popups/popups';

const ClassName = {
	ACTIVE: '_active',
	SELECTED: '_selected',
	EMPTY: '_empty',
};

class Filter {
	constructor(el) {
		this.init(el);
	}

	init(el) {
		this.container = el;

		if (!this.container) {
			return;
		}

		this.formEl = this.container.querySelector('.filter__form');
		const showMoreItems = this.container.querySelectorAll('._show-more');

		showMoreItems.forEach((el) => this._initShowMore(el));
		this._initPriceField();
		this._initGuideSizes();
		this._initSelected();
	}

	_initShowMore(content) {
		const toggle = content.nextElementSibling;

		toggle?.addEventListener('click', (evt) => {
			evt.preventDefault();

			const isExpanded = toggle.getAttribute('aria-expanded') === 'true';
			const toggleText = toggle.querySelector('span');

			if (!toggle.showText && !toggle.hideText) {
				toggle.showText = toggleText.textContent;
				toggle.hideText = toggle.getAttribute('data-filter-show-more');
			}

			toggleText.textContent = isExpanded ? toggle.showText : toggle.hideText;
			content.classList.toggle(ClassName.ACTIVE, !isExpanded);
			toggle.setAttribute('aria-expanded', !isExpanded);
		});
	}
	_initPriceField() {
		const priceContainer = this.container.querySelector('[data-filter-price]');

		if (!priceContainer) return;

		const priceInputs = priceContainer.querySelectorAll('input');
		const priceButtons = this.container.querySelectorAll(
			'[data-filter-price-value-from], [data-filter-price-value-to]',
		);
		const currencyChar = '₽';
		const isEmpty = (input) => !Number(input.mask.unmaskedValue);

		let activePriceButton = null;

		const updateActivePriceButton = (button) => {
			activePriceButton?.classList.remove(ClassName.SELECTED);

			if (button === null) {
				activePriceButton = null;
				return;
			}

			button.classList.add(ClassName.SELECTED);
			activePriceButton = button;
		};

		priceInputs.forEach((input) => {
			const customInput = input.closest('[data-input]').customInput;

			input.mask = IMask(input, {
				mask: `num ${currencyChar}`,
				lazy: false,
				blocks: {
					num: {
						mask: Number,
						thousandsSeparator: ' ',
					},
				},
			});

			if (isEmpty(input)) {
				input.value = '';
			}

			input.addEventListener('input', () => {
				if (isEmpty(input)) {
					input.value = '';
				}

				updateActivePriceButton(null);
			});
			input.addEventListener('blur', () => {
				if (isEmpty(input)) {
					customInput.setValue('');
				}
			});
		});

		priceButtons.forEach((button) => {
			const inputFrom = priceInputs[0].closest('[data-input]').customInput;
			const inputTo = priceInputs[1].closest('[data-input]').customInput;

			button.addEventListener('click', () => {
				if (button === activePriceButton) {
					inputFrom.setValue('');
					inputTo.setValue('');
					updateActivePriceButton(null);
					return;
				}

				const fromValue = button.getAttribute('data-filter-price-value-from');
				const toValue = button.getAttribute('data-filter-price-value-to');

				fromValue && inputFrom.setValue(fromValue);
				toValue && inputTo.setValue(toValue);

				updateActivePriceButton(button);
			});
		});
	}
	_initGuideSizes() {
		const opener = this.container.querySelector(
			'[data-popup-opener="guide-sizes"]',
		);
		let activePopupName = null;

		Popups.onOpen.add(() => {
			if (Popups.activePopupName.includes('filter')) {
				activePopupName = Popups.activePopupName;
			}
		});

		const onGuideSizesClose = () => {
			Popups.open(activePopupName);
			Popups.onClose.remove(onGuideSizesClose);
		};

		opener.addEventListener('click', () => {
			Popups.onClose.add(onGuideSizesClose);
		});
	}
	_initSelected() {
		const selectedContainer = this.container.querySelector(
			'.filter__buttons-list._selected',
		);

		if (!selectedContainer) {
			return;
		}

		const inputs = this.container.querySelectorAll('input[type="checkbox"]');
		const resetButton = this.container.querySelector('.filter__button._reset');

		const resetSelected = () => {
			inputs.forEach((input) => {
				input.checked = false;
				input.removeAttribute('checked');
				input.dispatchEvent(new Event('change'));
			});
			selectedContainer.classList.add(ClassName.EMPTY);
		};

		resetButton.addEventListener('click', () => {
			this.reset();
		});

		this.formEl.addEventListener('reset', () => {
			resetSelected();
		});

		const onInputChange = (input) => {
			const inputContainer = input.parentElement;

			if (!inputContainer.classList.contains('filter-button')) {
				return;
			}

			const cloneEl = inputContainer.cloneNode(true);
			const clonedInput = cloneEl.querySelector('input');
			clonedInput.addEventListener('change', () => {
				input.checked = clonedInput.checked;
				input.dispatchEvent(new Event('change'));
			});

			if (input.checked) {
				selectedContainer.prepend(cloneEl);
			} else {
				selectedContainer
					.querySelector(`[value="${input.value}"]`)
					?.parentElement?.remove();
			}

			selectedContainer.classList.toggle(
				ClassName.EMPTY,
				!(selectedContainer.children.length > 1),
			);
		};

		inputs.forEach((input) => {
			onInputChange(input);
			input.addEventListener('change', () => onInputChange(input));
		});
	}
	reset() {
		this.formEl.reset();
	}
}

document.querySelectorAll('[data-filter]').forEach((el) => new Filter(el));
