import env from '../utils/env';

class GrabScroll {
	constructor(el) {
		this.init(el);
	}

	init(el) {
		if (env.isMobile) {
			return;
		}

		const container = el;

		let isDragging = false;
		let startX;
		let scrollLeft;

		container.addEventListener('mousedown', (e) => {
			isDragging = true;
			startX = e.pageX - container.offsetLeft;
			scrollLeft = container.scrollLeft;
			container.style.cursor = 'grabbing';
		});

		container.addEventListener('mouseleave', () => {
			isDragging = false;
			container.style.cursor = 'grab';
		});

		container.addEventListener('mouseup', () => {
			isDragging = false;
			container.style.cursor = 'grab';
		});

		container.addEventListener('mousemove', (e) => {
			if (!isDragging) return;
			e.preventDefault();
			const x = e.pageX - container.offsetLeft;
			const walk = x - startX;
			container.scrollLeft = scrollLeft - walk;
		});
	}
}

document
	.querySelectorAll('[data-grab-scroll]')
	.forEach((el) => new GrabScroll(el));
