import Swiper from 'swiper';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

class MainHero {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-main-hero]');

		if (!this.container) {
			return;
		}

		const sliderContainer = this.container.querySelector('.swiper');
		const nextButton = this.container.querySelector('[data-nav-button-next]');
		const prevButton = this.container.querySelector('[data-nav-button-prev]');
		const pagination = this.container.querySelector('.swiper-pagination');

		this.slider = new Swiper(sliderContainer, {
			modules: [Autoplay, Pagination, Navigation],
			grabCursor: true,
			loop: true,
			speed: 600,
			autoplay: {
				delay: 6000,
			},
			pagination: {
				el: pagination,
			},
			navigation: {
				nextEl: nextButton,
				prevEl: prevButton,
			},
			on: {
				autoplayTimeLeft: (swiper, timeLeft, progress) => {
					sliderContainer.style.setProperty(
						'--autoplay-progress',
						progress.toFixed(2),
					);
				},
			},
		});
	}
}

export default new MainHero();
