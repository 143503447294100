import Popups from '../popups/popups';

const ClassName = {
	ACTIVE: '_active',
};
const MOBILE = '(max-width: 640px)';

class CatalogList {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-catalog-list]');

		if (!this.container) {
			return;
		}

		this.listContainer = this.container.querySelector('.catalog-list__list');
		this.layout = null;
		this.layoutButtons = [
			...this.container.querySelectorAll('[data-catalog-list-layout]'),
		];

		this.layoutButtons.forEach((button) => {
			button.addEventListener('click', () => {
				if (window.innerWidth <= 640) {
					this.setLayout(this.layout === 'grid' ? 'row-grid' : 'grid');
				} else {
					this.setLayout(button.getAttribute('data-catalog-list-layout'));
				}
			});
		});
		this.sortDropdown = this.container.querySelector('[data-dropdown]._sort');

		const mm = window.matchMedia(MOBILE);
		const onMMChange = (e) => {
			if (e.matches) {
				this.setLayout('row-grid');
				this.sortDropdown?.customSelect &&
					(this.sortDropdown.customSelect.open = false);
			} else {
				this.setLayout('grid');
				if (Popups.activePopupName === 'sort') {
					Popups.close(true);
				}
			}
		};

		mm.addEventListener('change', onMMChange);
		onMMChange(mm);

		this._initSort();
	}
	setLayout(newLayout) {
		if (newLayout === this.layout) return;

		this.layout = newLayout;
		this.container.classList.add('_changing');
		setTimeout(() => {
			this.container.setAttribute('data-catalog-list', this.layout);
			this.activeButton?.classList.remove(ClassName.ACTIVE);
			this.activeButton = this.layoutButtons.find(
				(button) =>
					button.getAttribute('data-catalog-list-layout') === this.layout,
			);
			this.activeButton?.classList.add(ClassName.ACTIVE);
		}, 150);

		setTimeout(() => {
			this.container.classList.remove('_changing');
		}, 200);
	}
	_initSort() {
		if (!this.sortDropdown) return;

		const sortPopup = Popups.getPopup('sort');
		const sortLabelMobile = this.container.querySelector(
			'[data-popup-opener="sort"] span',
		);
		const sortSelect = this.sortDropdown.querySelector('select');
		const sortRadioEls = sortPopup.querySelectorAll('input[type="radio"]');

		sortSelect.addEventListener('change', () => {
			sortLabelMobile.textContent = sortSelect.value;

			sortRadioEls.forEach(
				(radioEl) => (radioEl.checked = radioEl.value === sortSelect.value),
			);
		});

		sortRadioEls.forEach((radioEl) => {
			radioEl.addEventListener('change', () => {
				if (radioEl.checked) {
					sortLabelMobile.textContent = radioEl.value;
					this.sortDropdown.customSelect.value = radioEl.value;
				}
			});
		});
	}
}

export default new CatalogList();
